import {
  CreatePaymentsBatchInput,
  PayeeType,
  PaymentMethod,
  useAddPaymentsBatchPrototypesMutation,
  useCreatePaymentsApprovalMutation,
  useCreatePaymentsBatchMutation,
  useUpdatePaymentsBatchMutation,
} from 'api';
import { useMeta } from 'hooks/useMeta';
import { useNotification } from 'hooks/useNotification';
import { chain, compact, uniq } from 'lodash';
import { chunk } from 'lodash/fp';
import plur from 'plur';
import { useCallback, useMemo, useState } from 'react';
import { concurrent, ensureArray, ksuid, safeSum } from 'system';
import { maybe } from 'system/shapes';
import { z } from 'zod';
import { ClearableLabels } from '../types';
import { useGlAccounts } from './useGlAccounts';
import { useReconciliationConfig } from './useReconciliationConfigs';

export const clearingEntryPaymentsSchema = z.object({
  posted: z.string().length(10),
  paymentMethod: maybe(z.nativeEnum(PaymentMethod).or(z.literal('').transform(() => undefined))),
  total: z.number().min(0, { message: 'Total must be more than $0.00' }),
  payees: z.array(
    z.object({
      glId: z.string().min(1, { message: 'Must select an account' }),
      ref: maybe(z.string()),
      description: maybe(z.string()),
      total: z.number().min(0, { message: 'Amount must be more than $0.00' }),
      payee: z.nativeEnum(PayeeType),
      payeeId: z.string(),
      payeeName: z.string(),
      payeeClearables: z
        .object({
          id: z.string(),
          glId: z.string(),
          ownerId: z.string(),
          propertyId: z.string(),
          unitId: maybe(z.string()),
          clearingAmount: z
            .number()
            .min(0, { message: 'Amount must be greater than or equals to  $0.00' })
            .default(0),
          originalClearingAmount: maybe(z.number()),
        })
        .passthrough()
        .array()
        .min(1),
    })
  ),
});

export const useClearingEntryPayments = ({ balanceType, ...labels }: ClearableLabels) => {
  const { sendNotification } = useNotification();
  const { findGlAccount } = useGlAccounts();
  const { reconciliationConfigs, ...reconciliationConfigMeta } = useReconciliationConfig();
  const [createPaymentsBatchMutation, createPaymentsBatchMeta] = useCreatePaymentsBatchMutation();
  const [updatePaymentsBatchMutation, updatePaymentsBatchMeta] = useUpdatePaymentsBatchMutation();
  const [addPrototypes, addPrototypesMeta] = useAddPaymentsBatchPrototypesMutation();
  const [createApproval, createApprovalMeta] = useCreatePaymentsApprovalMutation();
  const { loading } = useMeta(
    createPaymentsBatchMeta,
    addPrototypesMeta,
    createApprovalMeta,
    updatePaymentsBatchMeta,
    reconciliationConfigMeta
  );

  const [rawBatchId, setRawBatchId] = useState(() => ksuid());
  const batchId = `post-${rawBatchId}`;
  const batchIdDisplay = batchId.slice(-5);

  const validGlIds = useMemo(
    () =>
      uniq(
        ensureArray(reconciliationConfigs)
          .filter((r) => r.bankId)
          .map((r) => r.scope.glId)
      ),
    [reconciliationConfigs]
  );

  // TODO: Use this in the UI to show the bank on each row
  const bankIdBySubId = useMemo(
    () =>
      Object.fromEntries(
        reconciliationConfigs.flatMap((r) =>
          ensureArray(r.scope.propertyOwnerIds).map((propertyOwnerId) => [
            `${r.scope.glId}#${propertyOwnerId.split('#')[1]}#${propertyOwnerId.split('#')[0]}`,
            {
              bankId: r.bankId,
              billingBankId: r.billingBankId,
              billingName: r.billingName,
            },
          ])
        )
      ),
    [reconciliationConfigs]
  );

  const addPaymentPrototypes = useCallback(
    async (values: z.infer<typeof clearingEntryPaymentsSchema>) => {
      setRawBatchId(ksuid());

      // This needs to be flattened and re-grouped per bank id
      // Need to have the reconciliation configs ready to extract the banks
      // If any of the selected subIds is not in a reconciliation group _or_ the group doesn't have a bank, then what?
      const prototypeInputs = chain(compact(values.payees))
        .flatMap(({ payeeClearables, ...payee }) =>
          payeeClearables.map((payeeClearable) => {
            const bankInfo =
              bankIdBySubId[`${payee.glId}#${payeeClearable.ownerId}#${payeeClearable.propertyId}`];
            return {
              payee,
              payeeClearable: {
                ...payeeClearable,
                clearingAmount:
                  findGlAccount(payee.glId)?.balanceType === balanceType
                    ? payeeClearable.clearingAmount
                    : -payeeClearable.clearingAmount,
              },
              bankId: bankInfo?.bankId,
              billingBankId: bankInfo?.billingBankId ?? bankInfo?.bankId,
              billingName: bankInfo?.billingName,
            };
          })
        )
        .groupBy((v) => [v.bankId, v.billingBankId, v.billingName].join())
        .map((group) => {
          if (!group[0].bankId) {
            console.error({
              amount: safeSum(
                group.map(({ payeeClearable: { clearingAmount } }) => clearingAmount)
              ),
              entries: group.map(
                ({ payee: { glId }, payeeClearable: { ownerId, propertyId } }) => ({
                  glId,
                  ownerId,
                  propertyId,
                })
              ),
            });

            // TODO: Add some UI for this so it doesn't fail post submission
            throw new Error("No bank set for this clearing entry's subId");
          }

          return {
            bankId: group[0].bankId ?? '',
            billingBankId: group[0].billingBankId,
            billingName: group[0].billingName,
            batchId: rawBatchId,
            posted: values.posted,
            amount: safeSum(group.map(({ payeeClearable: { clearingAmount } }) => clearingAmount)),
            payees: chain(group)
              .groupBy('payee.payeeId')
              .values()
              .value()
              .map((payeeGroup) => {
                const {
                  payee: { glId: rootGlId, description, payee, payeeId, payeeName },
                } = payeeGroup[0];

                return {
                  glId: rootGlId,
                  payee,
                  payeeId,
                  payeeName,
                  amount: safeSum(
                    payeeGroup.map(({ payeeClearable: { clearingAmount } }) => clearingAmount)
                  ),
                  description:
                    description ?? [labels.clearingEntryDescription, payeeName].join(' '),
                  payeeClearingAmounts: payeeGroup.map(
                    ({
                      payeeClearable: {
                        id: clearableId,
                        glId,
                        unitId,
                        ownerId,
                        propertyId,
                        clearingAmount = 0,
                      },
                    }) => ({
                      clearableId,
                      glId,
                      unitId,
                      ownerId,
                      propertyId,
                      amount: clearingAmount,
                    })
                  ),
                };
              }),
          };
        })
        .value()
        .map((input, index) => ({ ...input, index }));

      const batchHeaderInput: CreatePaymentsBatchInput = {
        id: rawBatchId,
        total: values.total,
        itemCount: prototypeInputs.flatMap(({ payees }) => payees).length,
        posted: values.posted,
        paymentMethod: values.paymentMethod,
      };

      const res = await createPaymentsBatchMutation({
        variables: { input: batchHeaderInput },
      });

      if (!res.data?.createPaymentsBatch.success) {
        console.error('Failed to create batch header');
        console.error(res.data?.createPaymentsBatch.error);
        return { success: false };
      }

      const separateMutations = chunk(50, prototypeInputs);
      const concurentMutations = 50;
      await concurrent(
        concurentMutations,
        separateMutations.map(
          (inputs) => () =>
            addPrototypes({
              variables: { inputs },
              update(cache, { data }) {
                if (data?.addPaymentsBatchPrototypes.success) {
                  const clearableIds = inputs.flatMap(({ payees }) =>
                    payees.flatMap(({ payeeClearingAmounts }) =>
                      payeeClearingAmounts.map(({ clearableId }) => clearableId)
                    )
                  );

                  clearableIds.forEach((id) => {
                    cache.modify({
                      id: cache.identify({ id, __typename: 'Clearable' }),
                      fields: { paymentsStatus: () => 'PENDING' },
                    });
                  });
                }
              },
            })
        )
      );

      const { data } = await createApproval({
        variables: { input: { batchId: batchHeaderInput.id } },
      });

      const label = plur(labels.clearingEntryLabel, prototypeInputs.length);

      if (data?.createPaymentsApproval?.success && data.createPaymentsApproval.approval?.id) {
        await updatePaymentsBatchMutation({
          variables: {
            input: { id: batchHeaderInput.id, approvalId: data.createPaymentsApproval.approval.id },
          },
        });

        sendNotification(`${label} submitted for approval`, 'success');
      } else {
        console.error(data?.createPaymentsApproval?.error);
        sendNotification(`There was a problem submitting your ${label} for approval`, 'error');
      }

      return { success: Boolean(data?.createPaymentsApproval?.success) };
    },
    [
      addPrototypes,
      balanceType,
      bankIdBySubId,
      createApproval,
      createPaymentsBatchMutation,
      findGlAccount,
      labels.clearingEntryDescription,
      labels.clearingEntryLabel,
      rawBatchId,
      sendNotification,
      updatePaymentsBatchMutation,
    ]
  );

  return {
    batchIdDisplay,
    addPaymentPrototypes,
    loading,
    validGlIds,
  };
};
